import React, { useMemo, useState } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";
import translate_word from "../../utils/translate";
import styles from './textinput.module.scss';
import { useEffect } from 'react';

function SelectInput({ label, cur, id, value, list, language, with_icon = true }) {
    const { t, i18n } = useTranslation();
    const router = useRouter();
    const [loader, setLoader] = useState(false);
    const currentLang = useMemo(() => {
        switch (router.locale) {
            case 'fr': return "Français";
            case 'en': return 'English';
            case "ru": return 'Русский';
        }
    });
    const currentLangFlag = useMemo(() => {
        switch (router.locale) {
            case 'fr': return <img className={styles.flag} src='/images/fr-flag.webp' />;
            case 'en': return <img className={styles.flag} src='/images/us-flag.png' />;
            case "ru": return <img className={styles.flag} src='/images/ru-flag.png' />;
        }
    });

    // useEffect(() => {
    //     document.cookie = `locale=${router.locale}; path=/`;
    // }, [router.locale]);
    return (
        <>
            {/* {loader && <div className='loader-cont'><ClipLoader color="#F9989C" size={50} /></div>} */}
            <div className={`select-input ${styles['select-input']}`}>
                <FormControl>
                    <InputLabel className={styles['select-input__label']} id={id}>
                        <div className='d-flex gap-2'>
                            {with_icon ? currentLangFlag : ''}
                            {label ? label : currentLang}
                        </div>
                    </InputLabel>
                    <Select
                        labelId={cur}
                        id={id}
                        value={ value}
                        renderValue={(p) => ''}
                        onChange={(e) => {
                            // setLoader(true);
                            document.cookie = `locale=${e.target.value}; path=/`;
                            // router.push(`${e.target.value}/${router.pathname}`);
                            router.push({
                                route: router.pathname,
                                query: router.query
                            }, router.asPath, { locale: e.target.value });
                            // .then(() => {
                            //     setLoader(false);
                            // });
                            // // props.handleChange(value);
                            if (e.target.value === 'fr') {
                                window.location = window.location.href.includes('/en/')
                                    ? window.location.href.replace('/en/', `/fr/`)
                                    : window.location.href.replace('/ru/', `/fr/`);
                            }
                            // i18n.changeLanguage(e.target.value);

                        }}
                    >
                        {/* <MenuItem>Выбрать</MenuItem> */}
                        {list ? list.map((item, index) => {
                            return (
                                item.code ?
                                    // <Link href={{
                                    //     pathname: router.pathname,
                                    // }} locale={item.code.toLowerCase()}>
                                    <MenuItem key={index} value={item.code.toLowerCase()}>
                                        {translate_word(item, language)
                                            ? translate_word(item, language.toUpperCase())
                                            : item.title}
                                    </MenuItem>
                                    // </Link>
                                    : <MenuItem key={index} value={item.id}>
                                        {translate_word(item, language)
                                            ? translate_word(item, language)
                                            : item.title}
                                    </MenuItem>);
                        }) : null}
                    </Select>
                </FormControl>
            </div>
        </>
    );
}
export default SelectInput;