const translate = (item, code) => {
    switch (code) {
        case "en":
            return item?.name_en || item?.title_en;
        case "RUS":
            return item?.name_ru || item?.title_ru;
        case "ru":
            return item?.name_ru || item?.title_ru;
        case "fr":
            return item?.name_fr || item?.title_fr;
        default:
            break;
    }
};

export default translate;